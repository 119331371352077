import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
    }, []);

  return (
   <div className="privacy-policy">
    <header>
        <div className="header-title-container">
          <h1 className="header-title varela-font">macros</h1>
          <h1 className="header-title mplus-font">&</h1>
          <h1 className="header-title varela-font">more</h1>
        </div>
      </header>
      

    <div className="terms-container">

      <h1>Terms and Conditions for Macros&More</h1>
      <p><strong>Last Updated:</strong> 11/07/2024</p>

      <section>
        <h2>Welcome to Macros&More!</h2>
        <p>
          By accessing or using our mobile application, you agree to abide by these Terms and Conditions.
          If you disagree with any part of the terms, please refrain from using our app.
        </p>
      </section>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p className="details">
          By creating an account, accessing, or using Macros&More, you agree to these Terms and Conditions.
          These Terms apply to all visitors, users, and others who access or use the app.
        </p>
      </section>

      <section>
        <h2>2. Definitions</h2>
        <ul className='collect'>
          <li><strong>User:</strong> Any individual who accesses or uses the Macros&More application.</li>
          <li><strong>Service:</strong> Refers to all functionalities provided by the Macros&More application.</li>
          <li><strong>App:</strong> Refers to the Macros&More mobile application.</li>
        </ul>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <p className="details">
          You are responsible for maintaining the confidentiality of your account and password and for any
          activities that occur under your account. You agree to provide accurate, current, and complete
          information during registration and to update such information to keep it accurate and complete.
        </p>
      </section>

      <section>
        <h2>4. Nutritional and Health Information Disclaimer</h2>
        <p className="details">
          Macros&More provides nutrition tracking and related information for educational purposes only. It is
          not intended as a substitute for medical advice, diagnosis, or treatment. Always seek the advice of a
          qualified healthcare provider before making any changes to your diet, exercise, or health regimen.
        </p>
      </section>

      <section>
        <h2>5. Use of Data and Privacy</h2>
        <p className="details">
          Your privacy is important to us. By using Macros&More, you agree to the collection, use, and sharing of
          your information as described in our Privacy Policy, which is incorporated by reference into these Terms
          and Conditions.
        </p>
      </section>

      <section>
        <h2>6. Ownership and Intellectual Property</h2>
        <p className="details">
          All materials within Macros&More, including but not limited to software code, design, and text, are
          created and owned by Andrew C. Smith. You are granted a limited, non-exclusive, non-transferable
          license to access and use the app for personal, non-commercial purposes only.
        </p>
      </section>

      <section>
        <h2>7. Prohibited Activities</h2>
        <ul className='collect'>
          <li>Use the app for any illegal or unauthorized purpose.</li>
          <li>Attempt to reverse engineer, decompile, or disassemble any part of the app.</li>
          <li>Upload, post, or otherwise transmit any content that infringes the intellectual property rights of any party.</li>
        </ul>
      </section>

      <section>
        <h2>8. Internet Connectivity Requirements</h2>
        <p className="details">
          Access to certain features of Macros&More requires an active internet connection. Macros&More is not
          responsible for any limitations or interruptions in service due to connectivity issues.
        </p>
      </section>

      <section>
        <h2>9. Data Charges</h2>
        <p className="details">
          You are responsible for any data charges or fees that may be incurred while using Macros&More.
        </p>
      </section>

      <section>
        <h2>10. Use of Third-Party Services</h2>
        <p className="details">
          Macros&More integrates with the USDA FoodData Central database to provide detailed food information.
          By using the app, you acknowledge and agree to comply with the terms of the FoodData Central database,
          accessible via the USDA’s website.
        </p>
      </section>

      <section>
        <h2>11. User-Generated Content</h2>
        <p className="details">
          If you upload or input custom content, such as personal meals or food items, you retain ownership of your
          content. By providing user-generated content, you grant Macros&More a limited, non-exclusive license to
          use, display, and store this content within the app for purposes of providing our services.
        </p>
      </section>

      <section>
        <h2>12. Changes in Service or Features</h2>
        <p className="details">
          Macros&More reserves the right to modify, suspend, or discontinue any feature or part of the app without notice.
        </p>
      </section>

      <section>
        <h2>13. Health Data Collection and Processing</h2>
        <p className="details">
          Macros&More may collect and process health-related data (such as calorie intake or dietary preferences) as
          necessary to provide our services.
        </p>
      </section>

      <section>
        <h2>14. Modification of Terms</h2>
        <p className="details">
          We reserve the right to modify or replace these Terms at any time. Continued use of the app following
          any changes constitutes acceptance of those changes.
        </p>
      </section>

      <section>
        <h2>15. Termination</h2>
        <p className="details">
          We may terminate or suspend your account at any time without notice or liability, for any reason, including
          if you breach these Terms.
        </p>
      </section>

      <section>
        <h2>16. Limitation of Liability</h2>
        <p className="details">
          Macros&More and its developers are not liable for any indirect, incidental, or consequential damages resulting
          from your use of the app.
        </p>
      </section>

      <section>
        <h2>17. Governing Law and Dispute Resolution</h2>
        <p className="details">
          These Terms shall be governed and construed in accordance with the laws of the United States. Any dispute
          arising from these Terms shall be subject to the exclusive jurisdiction of the courts of Rochester, New York.
        </p>
      </section>

      <section>
        <h2>18. Severability</h2>
        <p className="details">
          If any provision of these Terms is found to be unenforceable, the remaining provisions will continue to be valid and enforceable.
        </p>
      </section>

      <section>
        <h2>19. Contact Information</h2>
        <p className="details">
          For any questions regarding these Terms and Conditions, please contact us at:<br /><br />
          <strong>Email:</strong> support@andrewsmithdevelopment.com<br />
        </p>
      </section>
    </div>
    <footer>
       <nav>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
          </ul>
        </nav>

      <div className="signature footer-signature"> {/* Make sure both classes are applied */}
        <p>© 2024 Andrew Smith</p>
      </div>
    </footer>
    </div> 
  );
};

export default TermsAndConditions;
