import React from 'react';
import { Link } from 'react-router-dom';
import './ContactMe.css'; // External CSS file for styles

const ContactMe = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Me</Link></li>
            <li><Link to="/projects">Projects</Link></li>
            <li><Link to="/contact">Contact Me</Link></li>
            <li><Link to="/skills">Skills & Education</Link></li>
          </ul>
        </nav>
        <h1>Contact Me</h1>
      </header>

      <main>
        <h2>About Me</h2>
        <p>This is a paragraph about me</p>
      </main>

      <footer>
        <p>&copy; 2024 Andrew Smith</p>
      </footer>
    </div>
  );
};

export default ContactMe;
