import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './EmailVerification.css'; // Adjust the path as needed

const EmailVerification = () => {
  const [status, setStatus] = useState('Verifying...');
  const location = useLocation();

  useEffect(() => {
    // Check for query parameters in the URL
    const queryParams = new URLSearchParams(location.search);
    const verificationStatus = queryParams.get('status');
    const error = queryParams.get('error');

    if (verificationStatus === 'verified') {
      setStatus(<>Your email has been successfully verified! You can now log into your account.<br />
        <br />
        You may now close this window and return to the application.
      </>);
    } else if (verificationStatus === 'failed') {
      setStatus(`Verification failed: ${error || 'Please try again.'}`);
    } else {
      setStatus('Verifying...');
    }
  }, [location]);

  return (
    <div className='container'>
      <header className="header">
        <div className="header-title-container">
          <h1 className="header-title varela-font">macros</h1>
          <h1 className="header-title mplus-font">&</h1>
          <h1 className="header-title varela-font">more</h1>
        </div>
      </header>
      <div className="status-message">
        <p>{status}</p>
      </div>
      <div className="signature">
        <p> © 2024 Andrew Smith</p>
      </div>
    </div>
  );
};

export default EmailVerification;